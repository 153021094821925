<template>
  <div>
    <!-- ======= MAIN ======= -->
    <main id="main">
      <!-- ======= BAŞLIK ======= -->
      <section class="intro-single">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-lg-8">
              <div class="title-single-box">
                <h1 class="title-single">{{ HaberBilgisi.haberAdi }}</h1>
                <span class="color-text-c">{{ HaberBilgisi.haberTarihi }}</span>
              </div>
            </div>
            <div class="col-md-12 col-lg-4">
              <nav
                aria-label="breadcrumb"
                class="breadcrumb-box d-flex justify-content-lg-end"
              >
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/">Anasayfa</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Haber Detay
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <!-- ======= BAŞLIK ======= -->

      <!-- ======= HABER ======= -->
      <section class="news-single nav-arrow-b">
        <div class="container">
          <div class="row">
            <div class="col-lg-9">
              <div class="row">
                <div class="col-sm-12">
                  <div class="news-img-box">
                    <img :src="HaberBilgisi.kapak" alt="" class="img-fluid" />
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="post-information">
                    <ul class="list-inline text-center color-a">
                      <li class="list-inline-item mr-2">
                        <strong>Kategori: </strong>
                        <span class="color-text-c">Haber</span>
                      </li>
                      <li class="list-inline-item">
                        <strong>Tarih: </strong>
                        <span class="color-text-c">{{
                          HaberBilgisi.haberTarihi
                        }}</span>
                      </li>
                    </ul>
                  </div>
                  <div class="post-content color-text-a">
                    <p class="post-intro">
                      {{ HaberBilgisi.haberAciklamasi }}
                    </p>
                    <!-- <p class="color-a">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Itaque magni animi voluptas illum libero odio labore
                      aperiam quos iusto dolore asperiores corporis repudiandae
                      tempora dicta tenetur laborum, facilis dolores fugiat!
                    </p>
                    <p class="color-a">
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Nihil eum ipsam distinctio pariatur! Autem velit sapiente,
                      temporibus odit eaque quae obcaecati iste, architecto enim
                      reiciendis repellat illum sunt magnam expedita. Lorem
                      ipsum dolor, sit amet consectetur adipisicing elit. Velit
                      veritatis unde corrupti odit quam dolorem deleniti, harum
                      quo porro commodi. Earum nesciunt ipsam blanditiis
                      officiis ea a illum accusamus temporibus.
                    </p>
                    <blockquote class="blockquote">
                      <p class="mb-4 color-a">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Vero cupiditate maiores eos nostrum nulla
                        dignissimos adipisci possimus, similique assumenda
                        laborum deleniti ipsa? Rem hic voluptate quibusdam ex
                        blanditiis est repudiandae.
                      </p>
                      <p>
                        <cite class="color-b" title="Source Title"> Ahmet</cite>
                        <strong class="color-a"> GÜNCELİ</strong>
                      </p>
                    </blockquote>
                    <p class="color-a">
                      Donec rutrum congue leo eget malesuada. Curabitur aliquet
                      quam id dui posuere blandit. Vivamus suscipit tortor eget
                      felis porttitor volutpat. Quisque velit nisi, pretium ut
                      lacinia in, elementum id enim.
                    </p> -->
                  </div>
                  <div class="post-footer">
                    <div class="post-share">
                      <span>Haberi Paylaş: </span>
                      <ul class="list-inline socials">
                        <li class="list-inline-item">
                          <a href="#">
                            <i class="bi bi-facebook" aria-hidden="true"></i>
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <a href="#">
                            <i class="bi bi-twitter" aria-hidden="true"></i>
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <a href="#">
                            <i class="bi bi-instagram" aria-hidden="true"></i>
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <a href="#">
                            <i class="bi bi-linkedin" aria-hidden="true"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="title-box-d">
                    <h3 class="title-d">Haber Fotoğrafları</h3>
                  </div>
                  <hr />

                  <div class="row">
                    <div
                      v-for="(resim, index) in HaberBilgisi.Dosyalar"
                      :key="index"
                      class="col-md-4 mt-3"
                    >
                      <div class="card-box-b card-shadow news-box">
                        <div class="img-box-b">
                          <img
                            :src="resim.dosyaurl"
                            alt=""
                            class="img-b img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- HABER FOTOĞRAFLARI -->
              </div>
            </div>

            <Sidebar />
          </div>
        </div>
      </section>
      <!-- ======= HABER ======= -->
    </main>
    <!-- ======= MAIN ======= -->
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
export default {
  name: "HaberDetay",
  data() {
    return {
      HaberBilgisi: {},
    };
  },
  components: {
    Sidebar,
  },
  async created() {
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Haberler");
    this.haberdetaycek();
    this.kont = true;

    console.log(HaberBilgisi);
  },
  methods: {
    haberdetaycek() {
      const ID = this.$route.params.haberId;
      const haberler = this.$store.getters.getHaberler;
      this.HaberBilgisi = haberler[ID];
    },
  },
};
</script>

<style scoped>
</style>
